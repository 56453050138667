import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import "./index.css";
import Content from "./components/Content";
import pdf from "./assets/Taha_Zulfiqar_Resume.pdf";
import taha from "./assets/taha.JPG";

export const Home = () => (
  <div className="content-container">
    <h4 className="content-title">About Me</h4>
    <Image className="photo" src={taha}></Image>
    <div className="content-section">
      <Content>
        <p>Hi, and welcome to my personal website. </p>

        <p>
          {" "}
          My name is Taha Zulfiqar and I am currently working as a Software Engineer at Aviva Canada.
        </p>

        <p>
          I graduated from the{" "}
          <a href="https://www.utoronto.ca/">University of Toronto</a> with a
          Honours Bachelor of Science in{" "}
          <a href="https://web.cs.toronto.edu/">Computer Science</a> as of
          January 2020.
        </p>

        <p>
          I have 3 years of experience in software engineering, with a strong focus on backend development, AWS design, DevOps, Web Automation, Computer Vision, and end-to-end deployment with distributed systems.
        </p>

        <p>
          If you would like to get in touch, please reach out to me via{" "}
          <a href="mailto:taha.zulfiqar@alum.utoronto.ca">e-mail</a>. You can
          also download a copy of my{" "}
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            resume.
          </a>
        </p>
      </Content>
    </div>
  </div>
);
